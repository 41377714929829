import { Icon } from '@sb/design-system';
import type { Robot } from '@sb/remote-control/types';

import { WidgetView } from '../widget-panel';

interface DisconnectedWidgetProps {
  robot: Robot.ConvertedResponse;
  isVizbot: boolean;
}

export function DisconnectedWidget({
  robot,
  isVizbot,
}: DisconnectedWidgetProps) {
  return (
    <WidgetView className="tw-p-16 tw-gap-16">
      <Icon
        kind="exclamationTriangle"
        className="tw-text-mango tw-mx-auto tw-icon-42 -tw-mb-4"
      />

      <div>
        <h3 className="tw-font-medium tw-text-center">
          Robot is disconnected.
        </h3>

        <p className="tw-text-15 tw-mt-2 tw-text-center">
          The {isVizbot ? 'visualizer robot' : `robot “${robot.name}”`} has lost
          connection. Please try reconnecting.
        </p>
      </div>
    </WidgetView>
  );
}
