import { Button, HoldableButton } from '@sb/design-system';
import type { RecoveryType } from '@sb/routine-runner/RecoveryType';
import {
  useGuidedMode,
  useRobotJointConnectionState,
  useRobotSafeguardState,
  useRoutineRunnerHandle,
  useToast,
} from '@sbrc/hooks';

interface RecoveryButtonProps {
  recoveryType: RecoveryType;
  isVizbot: boolean;
  isDisabled?: boolean;
  guidedModeButtonLabel?: string | undefined;
}

export function RecoverButton({
  recoveryType,
  isVizbot,
  isDisabled,
  guidedModeButtonLabel,
}: RecoveryButtonProps) {
  const routineRunnerHandle = useRoutineRunnerHandle({ isVizbot });

  const { setToast } = useToast();

  const { runAdHocCommand, stopGuidedMode } = useGuidedMode({ isVizbot });

  const handleRecover = async (): Promise<void> => {
    try {
      await routineRunnerHandle.recover();
    } catch (error) {
      setToast({
        kind: 'error',
        message: error.message ?? error,
      });
    }
  };

  const handleGuidedModeRecover = async () => {
    return runAdHocCommand({ onRunCommand: handleRecover });
  };

  const isEstopped = useRobotSafeguardState({ isVizbot }) === 'eStop';

  const connectionStates = useRobotJointConnectionState({ isVizbot });

  const allJointsReady = connectionStates.every((state) => state === 'ready');

  const isButtonDisabled = isDisabled || isEstopped || !allJointsReady;

  const estopMessage = isEstopped && (
    <p className="tw-text-15 tw-text-center tw-text-red">
      All hardware E-Stop buttons must be reset before the robot can be
      recovered.
    </p>
  );

  const initializingJointsMessage = !isEstopped && !allJointsReady && (
    <p className="tw-text-15 tw-text-center">
      Waiting for joints to initialize.
    </p>
  );

  switch (recoveryType) {
    case 'Restart':
      return (
        <p className="tw-text-15 tw-text-center">
          You must restart the robot to recover from this failure. If this
          failure persists contact support.
        </p>
      );

    case 'NotRecoverable':
      return (
        <p className="tw-text-15 tw-text-center">
          The robot is in a non recoverable state. Please contact support for
          further assistance.
        </p>
      );

    case 'GuidedMode':
      return (
        <>
          <HoldableButton
            disabled={isButtonDisabled}
            onHold={handleGuidedModeRecover}
            onRelease={stopGuidedMode}
            variant="Filled"
            className="tw-rounded-6 tw-mt-4"
            data-testid="tap-to-recover-button-for-robot-recovery"
          >
            {guidedModeButtonLabel ?? 'Hold to recover'}
          </HoldableButton>
          {estopMessage}
          {initializingJointsMessage}
        </>
      );

    case 'ManualRecoveryMode':
    case 'Recoverable': {
      const buttonLabel =
        recoveryType === 'ManualRecoveryMode'
          ? 'Exit recovery'
          : 'Tap to recover';

      return (
        <>
          <Button
            disabled={isButtonDisabled}
            onClick={handleRecover}
            variant="Filled"
            className="tw-rounded-6 tw-mt-4"
            data-testid="tap-to-recover-button-for-robot-recovery"
          >
            {buttonLabel}
          </Button>
          {estopMessage}
          {initializingJointsMessage}
        </>
      );
    }

    default:
      recoveryType satisfies never;

      return null;
  }
}
