import { useRouter } from 'next/router';

import { Icon } from '@sb/design-system';

export function HomeButton() {
  const { push } = useRouter();

  return (
    <Icon
      kind="tooltip"
      className="tw-icon-28 tw-text-label-secondary"
      role="button"
      aria-label="Home"
      onClick={() => push('/')}
    />
  );
}
