import { Icon } from '@sb/design-system';

import { WidgetView } from '../widget-panel';

import { UnbrakeButton } from './UnbrakeButton';

interface UnbrakeWidgetProps {
  isVizbot: boolean;
}

export function RobotBrakedWidget({ isVizbot }: UnbrakeWidgetProps) {
  return (
    <WidgetView className="tw-p-16 tw-gap-16">
      <Icon
        kind="exclamationBrake"
        className="tw-text-mango tw-mx-auto tw-icon-42 -tw-mb-4"
      />

      <div>
        <h3 className="tw-font-medium tw-text-center">Robot is braked.</h3>

        <p className="tw-text-15 tw-mt-2 tw-text-center">
          {isVizbot
            ? 'Tap unbrake to start interacting with the visualizer'
            : 'Please review the settings below and tap unbrake to start interacting with the live robot'}
          .
        </p>
      </div>

      <UnbrakeButton isVizbot={isVizbot} />
    </WidgetView>
  );
}
